import { css } from "@emotion/react"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../../components/layout/Layout"
import { BlueBtn } from "../../components/layout/styledComponents.js"
import Seo from "../../components/seo"

export default function Scan() {
  return (
    <Layout learnMore>
      <Seo
        title="3D Ct Scan Colorado Springs, CO - pikespeakoralsurgery"
        description="The 3D CT scans creates an extremely accurate & detailed images that Dr. Kunkel utilizes to determine the right treatment for his patients"
      />
      <section className="container" style={{ margin: "128px auto" }}>
        <StaticImage
          src="../../images/i-cat.png"
          placeholder="blurred"
          css={css`
            margin: 0 auto 64px;
            max-width: 652px;
            display: block;
          `}
        />

        <p>
          A 3D CT scan is an innovative digital imaging method that provides a
          quick and painless examination of your teeth, jaws, and face. Unlike
          the more conventional radiographic techniques, the 3D CT offers the
          creation of extremely accurate, detailed images that are
          three-dimensional. These images allow us to visualize critical
          anatomical structures and to better understand your condition. This
          helps us to make the most accurate diagnosis and treatment
          recommendations. 3D CT scanning may be necessary to determine a
          variety of oral conditions, like the location and position of impacted
          teeth, locate important nerves in the jaw, evaluate cases of facial
          trauma, or identify and characterize several different types of
          tumors.
        </p>

        <p>
          Patient comfort and safety are our top priority. Through the use of
          the most advanced 3D CT scanning, the team at Pikes Peak Oral Surgery
          & Dental Implant Center is able to obtain important information for
          treatment while reducing radiation exposure to our patients. 3D CT
          scanning allows us to make the most accurate diagnosis possible in
          order to eliminate potential surprises during your treatment, prevent
          any unnecessary procedures, and better prepare our staff for the
          upcoming treatment. As a result, this tool generally provides patients
          with a shorter operating time and fewer post-surgical complications.
        </p>

        <p>
          At Pikes Peak Oral Surgery & Dental Implant Center, we work hard to
          provide our patients with the highest level of quality and technology
          possible to ensure optimal clinical results and recovery after the
          procedure.
        </p>

        <a
          href="#contact"
          style={{
            display: "block",
            margin: "64px auto 0",
            width: "max-content",
          }}
        >
          <BlueBtn>Book an appointment now</BlueBtn>
        </a>
      </section>
    </Layout>
  )
}
